<template>
    <div class="sidebar-thin custom-container">
        <TheSidebar/>
        <ThePageHeadingBar
            :pageHeading="$route.meta.title"
            fullSize
            backArrow
            @goBack="$router.push( { name: 'CreditMemoReconciliationSearch' } )"
        />
        <section class="main">
            <v-form
                ref="form"
                noValidate
            >
                <v-container class="create-credit-memo">
                    <!-- Supplier -->
                    <v-row class="create-credit-memo__form-field">
                        <v-col cols="2">
                            <label for="supplier">
                                Supplier
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <p id="supplier">{{ models.supplier.name }}</p>
                        </v-col>
                    </v-row>
                    <!-- Location -->
                    <v-row class="create-credit-memo__form-field">
                        <v-col cols="2">
                            <label for="location">
                                Location
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <p id="location">{{ locationNameFriendly }}</p>
                        </v-col>
                    </v-row>
                    <!-- RRA Number -->
                    <v-row class="create-credit-memo__form-field">
                        <v-col cols="2">
                            <label for="rraNumber">
                                RRA Number
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <p id="rraNumber">{{ models.rraNumber }}</p>
                        </v-col>
                    </v-row>
                    <!-- RRA Number -->
                    <v-row class="create-credit-memo__form-field">
                        <v-col cols="2">
                            <label for="transactionNumber">
                                Transaction Number
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <p id="transactionNumber">{{ models.transactionNumber }}</p>
                        </v-col>
                    </v-row>
                    <!-- Credit Memo Number -->
                    <v-row class="create-credit-memo__form-field">
                        <v-col cols="2">
                            <label for="creditMemoNumber">
                                Credit Memo Number
                                <RequiredAsterisk/>
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field
                                id="creditMemoNumber"
                                v-model="models.creditMemoNumber"
                                type="text"
                                outlined
                                dense
                                hideDetails
                                required
                                :rules="[ rules.required ]"
                            />
                        </v-col>
                    </v-row>
                    <!-- Credit Memo Date -->
                    <v-row class="create-credit-memo__form-field">
                        <v-col cols="2">
                            <label for="creditMemoDate">
                                Credit Memo Date
                                <RequiredAsterisk/>
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <InputDatePicker
                                label="Credit Memo Date"
                                :date.sync="models.creditMemoDate"
                                outlined
                                :additional-rules="[rules.required]"
                            />
                        </v-col>
                    </v-row>
                    <!-- Credit Memo Total -->
                    <v-row class="create-credit-memo__form-field">
                        <v-col cols="2">
                            <label for="creditMemoTotal">
                                Credit Memo Total
                                <RequiredAsterisk/>
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <InputCurrency
                                id="creditMemoTotal"
                                v-model="models.creditMemoTotal"
                                required
                            />
                        </v-col>
                    </v-row>
                    <!-- Credit Memo Notes -->
                    <v-row class="create-credit-memo__form-field">
                        <v-col cols="2">
                            <label for="creditMemoNotes">
                                Credit Memo Notes
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <v-textarea
                                id="creditMemoNotes"
                                v-model="models.creditMemoNotes"
                                type="text"
                                outlined
                                dense
                                hideDetails
                                required
                            />
                        </v-col>
                    </v-row>
                    <!-- RRA Lines -->
                    <v-row class="create-credit-memo__form-field">
                        <v-col cols="2">
                            <label for="rraLines">
                                Return Transaction Lines
                                <RequiredAsterisk/>
                            </label>
                        </v-col>
                        <v-col cols="10">
                            <TableBaseFilterable
                                id="rraLines"
                                :error="tableError"
                                :filter.sync="filter"
                                :items="availableTransactionLines"
                                :headers="returnTransactionLineHeaders"
                                :loading="inFlight"
                                :selected.sync="models.selectedTransactionLines"
                                outlined
                                filterLabel="Filter Return Transaction Lines..."
                                noDataText="No Return Transaction Lines"
                                hideAdd
                                showSelect
                            >
                                <template #[`item.unitPrice`]="{ item }">
                                    {{ FormatUtil.toCurrency( item.unitPrice ) }}
                                </template>
                            </TableBaseFilterable>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </section>
        <ThePageFooterBar>
            <v-container class="create-credit-memo-footer">
                <v-row>
                    <v-spacer/>
                    <v-btn
                        :disabled="createInFlight"
                        :loading="createInFlight"
                        class="create-credit-memo-footer__button"
                        outlined
                        @click="clearButtonHandler()"
                    >
                        Clear
                    </v-btn>
                    <v-btn
                        :disabled="createInFlight"
                        :loading="createInFlight"
                        class="create-credit-memo-footer__button"
                        color="primary"
                        @click="saveButtonHandler()"
                    >
                        Save
                    </v-btn>
                </v-row>
            </v-container>
        </ThePageFooterBar>
    </div>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Routes
import CreditMemoRoute from '@/router/routes/CreditMemoReconciliation';

// Utils
import FormatUtil from '@/utils/FormatUtil';
import ValidationUtil from '@/utils/ValidationUtil';

// Constants
import returnTransactionLineHeaders from '@/constants/creditMemo/returnTransactionLineTableHeaders';

// Components
import TheSidebar from '@/views/common/TheSidebar';
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import ThePageFooterBar from '@/components/ThePageFooterBar';
import RequiredAsterisk from '@/components/base/RequiredAsterisk';
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';
import InputCurrency from '@/components/base/inputs/InputCurrency';
import InputDatePicker from '@/components/InputDatePicker';

export default {
    name: 'CreditMemoCreate',
    components: {
        InputDatePicker,
        TheSidebar,
        ThePageHeadingBar,
        ThePageFooterBar,
        RequiredAsterisk,
        TableBaseFilterable,
        InputCurrency,
    },
    data() {
        return {
            FormatUtil,
            returnTransactionLineHeaders,
            filter: '',

            rules: {
                required: ValidationUtil.required,
                requiredObject: ValidationUtil.requiredObject,
                requiredArray: ValidationUtil.requiredArray,
            },
            models: {
                location: {},
                supplier: {},
                rraNumber: '',
                creditMemoNumber: '',
                transactionNumber: '',
                creditMemoDate: '',
                creditMemoTotal: 0,
                creditMemoNotes: '',
                /** @type { import('@/typedefs/transactionItem').transactionItem[] } */
                selectedTransactionLines: [],
            },
            tableError: false,
            /** @type { import('@/typedefs/transactionItem').transactionItem[] } */
            availableTransactionLines: [],
            /** @type { Boolean } */
            createInFlight: false,
        };
    },
    computed: {
        ...mapGetters( {
            hasSelectedTransaction: 'CreditMemo/hasSelectedTransaction',
        } ),
        ...mapState( {
            inFlight: ( state ) => state.CreditMemo.inFlight,
            selectedTransaction: ( state ) => state.CreditMemo.selectedTransaction,
        } ),
        /** @returns { String } */
        locationNameFriendly() {
            return this.models.location && this.models.location.name
                ? FormatUtil.locationNameFriendly( this.models.location.name )
                : '';
        },
    },
    async mounted() {
        await this.$store.dispatch( 'GlobalResources/init' );
        if ( this.hasSelectedTransaction ) {
            this.setInitialModelsFromState();
            await this.getAvailableTransactions();
        } else {
            await this.$router.push( { path: CreditMemoRoute.path } );
        }
    },
    beforeDestroy() {
        // Set inFlight to false b/c both this page and the search page rely on it to indicate the table's loading state,
        // and if it is still true from the request fired on this page, the loading stripe will be visible when the user
        // navigates to the credit memo search page
        this.$store.commit( 'CreditMemo/SET_IN_FLIGHT', { boolean: false } );
    },
    methods: {
        setInitialModelsFromState() {
            this.models.location = this.selectedTransaction.location;
            this.models.supplier = this.selectedTransaction.supplier;
            this.models.rraNumber = this.selectedTransaction.rraNumber;
            this.models.creditMemoDate = this.selectedTransaction.rraDate;
            this.models.transactionNumber = this.selectedTransaction.transactionNumber;
        },

        checkTableErrorState() {
            this.tableError = this.models.selectedTransactionLines.length === 0;
        },
        clearValidate() {
            this.tableError = false;
            return this.$refs.form.resetValidation();
        },
        validate() {
            this.checkTableErrorState();
            return this.$refs.form.validate() && !this.tableError;
        },

        async getAvailableTransactions() {
            const availableTransactions = await this.$store.dispatch( 'CreditMemo/getRRAs', {
                rraNumber: this.selectedTransaction.rraNumber,
            } );
            this.availableTransactionLines = availableTransactions[ 0 ] && availableTransactions[ 0 ].requestTransItemDtos
                // Give each item an id so v-data-table knows how to properly select a single item and not check ALL items when you check a single item
                ? availableTransactions[ 0 ].requestTransItemDtos.map( ( transaction ) => ( { ...transaction, id: transaction.rraItemId } ) )
                : [];
        },

        clearButtonHandler() {
            this.models.location = {};
            this.models.supplier = {};
            this.models.rraNumber = '';
            this.models.creditMemoNumber = '';
            this.models.creditMemoDate = '';
            this.models.creditMemoTotal = 0;
            this.models.creditMemoNotes = '';
            this.models.selectedTransactionLines = [];
            this.clearValidate();
        },
        async saveButtonHandler() {
            if ( this.validate() ) {
                const creditMemo = {
                    locationId: 'id' in this.models.location
                        ? this.models.location.id
                        : null,
                    supplierId: 'id' in this.models.supplier
                        ? this.models.supplier.id
                        : null,
                    rraNumber: this.models.rraNumber,
                    creditMemoNumber: this.models.creditMemoNumber,
                    createCreditMemoDate: this.createCreditMemoDate,
                    creditMemoTotal: this.models.creditMemoTotal,
                    creditMemoNotes: this.models.creditMemoNotes,
                    rraItemIds: this.models.selectedTransactionLines.map( ( transaction ) => transaction.rraItemId ),
                };

                this.createInFlight = true;
                try {
                    const id = await this.$store.dispatch( 'CreditMemo/create', { creditMemo } );
                    await this.$router.push( { name: 'CreditMemoReconciliationDetail', params: { id } } );
                } finally {
                    this.createInFlight = false;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
main {
    grid-area: main;
    margin-bottom: 150px;
}

.create-credit-memo {
    margin-left: .25rem;
}

.create-credit-memo-footer {
    margin-right: .25rem;

    &__button {
        margin-left: 1rem;
        width: 100px;
    }
}
</style>
